import { MediaUrl } from "../../redux/api/menuDataApi.ts";

export interface BaseCampaign {
  id: string;
  apiId: string;
  type: CampaignPricingRule;
  name: string;
  startDateTime: Date;
  endDateTime: Date;
  mediaUrl?: MediaUrl;
  Xs?: number[];
  updatedAt: string;
}

type Campaign = BaseCampaign | XPerGroupForYCampaign;
export default Campaign;

export type XPerGroupForYCampaign = BaseCampaign & {
  type: CampaignPricingRule.X_PER_GROUP_FOR_Y;
  x_per_group_for_y: { numberOfProducts: number; productIds: number[]; name: string }[];
};

export enum CampaignPricingRule {
  NONE = "NONE", // No calculations,
  WAS_NOW = "WAS_NOW", // Was 100$, now 59$
  BUY_X_PAY_Y = "BUY_X_PAY_Y", // Buy 3, pay for 2 !Cheapest for free!
  X_PERCENTAGE_OFF = "X_PERCENTAGE_OFF", // 15% discount if 2 products or more which are in the campaign
  XTH_ITEM_Y_PERCENTAGE_OFF = "XTH_ITEM_Y_PERCENTAGE_OFF", // 2nd article 50% off
  X_FOR_Y = "X_FOR_Y", // x number of items for y amount
  X_PER_GROUP_FOR_Y = "X_PER_GROUP_FOR_Y", // x number of items per group, for y amount
}
